const NewsLetterIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg
            viewBox={viewBox ? viewBox : "-10 0 100 100"}
            stroke={stroke ? stroke : "currentColor"}
            fill="none"
            strokeWidth="3px"
            {...otherProps}
        >
            <line x1="29.1" y1="13.7" x2="29.1" y2="13.7" />
            <line x1="34.6" y1="13.7" x2="34.6" y2="13.7" />
            <path d="M46.5,9.5v5.6c0,1.5-1.3,2.8-2.8,2.8H27c-1.5,0-2.8-1.3-2.8-2.8V9.5" />
            <line x1="28.4" y1="83.5" x2="42.3" y2="83.5" />
            <line x1="26.3" y1="43" x2="43" y2="43" />
            <line x1="20.7" y1="50" x2="31.8" y2="50" />
            <line x1="26.3" y1="57" x2="37.4" y2="57" />
            <path d="M40.3,41.7l1.8-4.7c0.5-1.3,2-2.4,3.3-2.4h40.8c1.3,0,1.9,1.1,1.4,2.4l-10,25.9c-0.5,1.3-2,2.4-3.3,2.4H33.4 c-1.3,0-1.9-1.1-1.4-2.4L40.3,41.7" />
            <line x1="46.5" y1="39" x2="69.7" y2="60.5" />
            <line x1="87.7" y1="35.7" x2="64" y2="50" />
            <line x1="39.7" y1="59.9" x2="46.4" y2="59.9" />
            <line x1="41.9" y1="55.5" x2="50.8" y2="55.5" />
            <path d="M58.4,65.4v19.6c0,3.1-2.5,5.6-5.6,5.6H17.9c-3.1,0-5.6-2.5-5.6-5.6V15.1c0-3.1,2.5-5.6,5.6-5.6h34.9 c3.1,0,5.6,2.5,5.6,5.6v19.6" />
        </svg>
    )
}

export default NewsLetterIcon
