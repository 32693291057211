import clsx from "clsx"
import {Script} from "gatsby-script"
import {AnchorHTMLAttributes, FC, Fragment, useContext} from "react"
import I18nContext from "~/contexts/I18nContext"

interface PrivacyPolicyProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

export const Head = () => {
    const i18n = useContext(I18nContext)
    return (
        <>
            <Script
                defer
                async
                id="iubenda-script"
                src="https://cdn.iubenda.com/iubenda.js"
                title={`${i18n.language}_fix_for_iubenda`}
            />
        </>
    )
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = props => {
    const {className, ...otherProps} = props
    const i18n = useContext(I18nContext)

    return (
        <Fragment>
            {i18n.language === "it" && (
                <Fragment>
                    <ul className="sub-list-gck6">
                        <li>
                            <a
                                href="https://www.iubenda.com/privacy-policy/77024075"
                                className={clsx(
                                    "iubenda-nostyle no-brand iubenda-noisframe iubenda-embed generic-link-hb2c text-hb2c",
                                    className,
                                )}
                                title="Privacy Policy "
                                {...otherProps}
                            >
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.iubenda.com/privacy-policy/77024075/cookie-policy"
                                className={clsx(
                                    "iubenda-nostyle no-brand iubenda-noisframe iubenda-embed generic-link-hb2c text-hb2c",
                                    className,
                                )}
                                title="Cookie Policy "
                                {...otherProps}
                            >
                                Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className={clsx("iubenda-advertising-preferences-link", className)}
                                title="Impostazioni Cookie "
                                {...otherProps}
                            >
                                Impostazioni cookie
                            </a>
                        </li>
                    </ul>
                </Fragment>
            )}
            {i18n.language === "en" && (
                <Fragment>
                    <ul className="sub-list-gck6">
                        <li>
                            <a
                                href="https://www.iubenda.com/privacy-policy/48365373"
                                className={clsx(
                                    "iubenda-nostyle no-brand iubenda-noisframe iubenda-embed generic-link-hb2c text-hb2c",
                                    className,
                                )}
                                title="Privacy Policy "
                                {...otherProps}
                            >
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.iubenda.com/privacy-policy/48365373/cookie-policy"
                                className={clsx(
                                    "iubenda-nostyle no-brand iubenda-noisframe iubenda-embed generic-link-hb2c text-hb2c",
                                    className,
                                )}
                                title="Cookie Policy "
                                {...otherProps}
                            >
                                Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className={clsx("iubenda-advertising-preferences-link", className)}
                                title="Cookie Preferences "
                                {...otherProps}
                            >
                                Cookie Preferences
                            </a>
                        </li>
                    </ul>
                </Fragment>
            )}
        </Fragment>
    )
}

export default PrivacyPolicy
