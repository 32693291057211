exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-us-template-tsx": () => import("./../../../src/templates/AboutUsTemplate.tsx" /* webpackChunkName: "component---src-templates-about-us-template-tsx" */),
  "component---src-templates-authentication-page-template-tsx": () => import("./../../../src/templates/AuthenticationPageTemplate.tsx" /* webpackChunkName: "component---src-templates-authentication-page-template-tsx" */),
  "component---src-templates-case-history-post-template-tsx": () => import("./../../../src/templates/CaseHistoryPostTemplate.tsx" /* webpackChunkName: "component---src-templates-case-history-post-template-tsx" */),
  "component---src-templates-contacts-template-tsx": () => import("./../../../src/templates/ContactsTemplate.tsx" /* webpackChunkName: "component---src-templates-contacts-template-tsx" */),
  "component---src-templates-home-page-template-tsx": () => import("./../../../src/templates/HomePageTemplate.tsx" /* webpackChunkName: "component---src-templates-home-page-template-tsx" */),
  "component---src-templates-magazine-post-template-tsx": () => import("./../../../src/templates/MagazinePostTemplate.tsx" /* webpackChunkName: "component---src-templates-magazine-post-template-tsx" */),
  "component---src-templates-magazine-template-tsx": () => import("./../../../src/templates/MagazineTemplate.tsx" /* webpackChunkName: "component---src-templates-magazine-template-tsx" */),
  "component---src-templates-products-template-tsx": () => import("./../../../src/templates/ProductsTemplate.tsx" /* webpackChunkName: "component---src-templates-products-template-tsx" */),
  "component---src-templates-value-network-template-tsx": () => import("./../../../src/templates/ValueNetworkTemplate.tsx" /* webpackChunkName: "component---src-templates-value-network-template-tsx" */)
}

