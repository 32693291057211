import clsx from "clsx"
import {graphql, useStaticQuery} from "gatsby"
import React, {useContext, useState} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import Button from "~/components/UI/Button/Button"
import InputText from "~/components/UI/InputText/InputText"
import I18nContext from "~/contexts/I18nContext"
import CheckIcon from "~/icons/CheckIcon"
import ErrorIcon from "~/icons/ErrorIcon"
import {useLabelsQuery} from "~/queries/LabelsQuery"
import {emailRegex} from "../../utilities/RegexStrings"
import CheckBox from "../UI/CheckBox/CheckBox"

import "./SubscribeForm.css"

interface SubscribeFormProps extends React.FormHTMLAttributes<HTMLFormElement> {}

type SubmitStatus = undefined | "success" | "error"

type SubscribeFields = {
    Email: string
    DecisionBox: "on" | ""
}

const SubscribeForm: React.FC<SubscribeFormProps> = props => {
    const {className, children, ...otherProps} = props
    const [submitStatus, setSubmitStatus] = useState<SubmitStatus>()

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm<SubscribeFields>()
    const i18n = useContext(I18nContext)
    const labels = useLabelsQuery()

    const data: Queries.SubscribeFormQuery = useStaticQuery(graphql`
        query SubscribeForm {
            allContentfulFooter {
                nodes {
                    subscribeButton
                    newsletterEmailPlaceholder
                    subscribePrivateArea
                    node_locale
                }
            }
        }
    `)

    let currentData = data.allContentfulFooter.nodes[0]!
    for (const translation of data.allContentfulFooter.nodes) {
        if (translation.node_locale === i18n.language) {
            currentData = translation
            break
        }
    }

    const onFormSubmitHandler: SubmitHandler<SubscribeFields> = async data => {
        const url =
            "https://forms.zohopublic.eu/adkaora/form/AdKaoranewwebsiteISCRIVITIALLANEWSLETTER/formperma/z4z8PtQRNmH7KKSh-gbtRDiddqRxPZm52d6sBcxq8Yc/htmlRecords/submit"

        const formData = new FormData()

        for (const field in data) {
            type ObjectKey = keyof typeof data
            const fieldKey = field as ObjectKey
            if (typeof data[fieldKey] === "boolean") {
                const bool = data[fieldKey] as unknown as boolean
                if (bool) {
                    formData.append(field, "on")
                } else {
                    formData.append(field, "")
                }
                continue
            }
            formData.append(field, data[fieldKey])
        }

        const requestOptions: RequestInit = {
            method: "POST",
            mode: "cors",
            body: formData,
            redirect: "manual",
            cache: "no-cache",
            credentials: "same-origin",
        }

        await fetch(url, requestOptions).catch((error: Error) => {})
        setSubmitStatus("success")
    }

    return (
        <form className={clsx("form-wwy9", className)} onSubmit={handleSubmit(onFormSubmitHandler)} {...otherProps}>
            <p className="cta-wwy9">{currentData.subscribePrivateArea}</p>
            <InputText
                id="subscription-mail-input"
                type="email"
                placeholder={currentData.newsletterEmailPlaceholder}
                invalid={errors.Email !== undefined}
                containerClassName="email-container-wwy9"
                variant="footer"
                className="email-wwy9"
                {...register("Email", {
                    required: true,
                    pattern: emailRegex,
                })}
            />
            <Button color="white" type="submit" className="button-wwy9">
                {currentData.subscribeButton}
            </Button>
            <CheckBox
                label={labels.privacyAcceptance}
                className="checkbox-wwy9"
                invalid={errors.DecisionBox !== undefined}
                {...register("DecisionBox", {required: true})}
            />
            {submitStatus === "success" && (
                <div className="overlay-wwy9">
                    <div className="modal-wwy9">
                        <CheckIcon className="check-icon-wwy9" />
                        <span className="submit-success-message-wwy9">{labels.submitSuccessMessage}</span>
                    </div>
                </div>
            )}
            {submitStatus === "error" && (
                <div className="overlay-wwy9">
                    <div className="modal-wwy9">
                        <ErrorIcon className="check-icon-wwy9" />
                        <span className="submit-success-message-wwy9">Error</span>
                    </div>
                </div>
            )}
        </form>
    )
}

export default SubscribeForm
