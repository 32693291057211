import clsx from "clsx"
import React, {useContext, useEffect, useRef, useState} from "react"
import GenericLink from "~/components/UI/GenericLink/GenericLink"
import I18nContext from "~/contexts/I18nContext"
import CloseIcon from "~/icons/CloseIcon"
import {useLinkTranslatorQuery} from "~/queries/LinkTranslatorQuery"
import {useLinksQuery} from "~/queries/LinksQuery"

import {StaticImage} from "gatsby-plugin-image"
import "./NavMenu.css"

interface NavMenuProps extends React.HTMLAttributes<HTMLDivElement> {
    visible: boolean
    onClose: (event: React.MouseEvent<HTMLElement>) => void
}

const NavMenu: React.FC<NavMenuProps> = props => {
    const {className, visible, onClose, ...otherProps} = props
    const [_visible, _setVisible] = useState(false)
    const menuRef = useRef<HTMLDivElement | null>(null)
    const i18n = useContext(I18nContext)
    const links = useLinksQuery()

    useEffect(() => {
        if (!menuRef.current || visible === _visible) return
        menuRef.current.ontransitionend = () => {
            if (!menuRef.current) return
            menuRef.current.ontransitionend = null
            _setVisible(state => !state)
        }
        if (_visible) {
            menuRef.current.classList.remove("visible-fe8f")
        } else {
            menuRef.current.classList.add("visible-fe8f")
        }
    }, [visible])

    const onCloseMenuClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClose(event)
    }

    return (
        <div className={clsx("nav-menu-fe8f", className)} ref={menuRef} {...otherProps}>
            <header className="nav-header-fe8f">
                <GenericLink to={links.homepage.url} onClick={onClose} withoutTransition className="logo-link-fe8f">
                    <StaticImage src="../../images/logo-white.png" alt="Logo" placeholder="blurred" height={20} />
                </GenericLink>
                <button className="close-button-fe8f" onClick={onCloseMenuClickHandler}>
                    <CloseIcon className="close-icon-fe8f" />
                </button>
            </header>
            <menu className="menu-links-fe8f">
                <li>
                    <GenericLink
                        to={links.homepage.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.homepage.name}
                    </GenericLink>
                </li>
                <li>
                    <GenericLink
                        to={links.about.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.about.name}
                    </GenericLink>
                </li>
                <li>
                    <GenericLink
                        to={links.products.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.products.name}
                    </GenericLink>
                </li>
                <li>
                    <GenericLink
                        to={links.valueNetwork.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.valueNetwork.name}
                    </GenericLink>
                </li>
                <li>
                    <GenericLink
                        to={links.magazine.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.magazine.name}
                    </GenericLink>
                </li>
                {/* <li>
                    <GenericLink
                        to={links.privateArea.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.privateArea.name}
                    </GenericLink>
                </li> */}
                <li>
                    <GenericLink
                        to={links.contacts.url}
                        className="menu-link-item-fe8f"
                        activeClassName="menu-link-active-fe8f"
                        onClick={onClose}
                        withoutTransition
                    >
                        {links.contacts.name}
                    </GenericLink>
                </li>
                <li className="languages-container-fe8f">
                    {i18n.supportedLaguages.map(language => (
                        <GenericLink
                            key={`${language}_key`}
                            to={useLinkTranslatorQuery(language)}
                            className="menu-link-lang-fe8f"
                            activeClassName="menu-link-lang-active-fe8f"
                            onClick={onClose}
                            withoutTransition
                        >
                            {language}
                        </GenericLink>
                    ))}
                </li>
            </menu>
        </div>
    )
}

export default NavMenu
