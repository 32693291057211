export type PostType = "blog" | "case-history" | "academy" | "events" | "analysis" | "video" | "on-demand" | "podcast"

export enum PostTypes {
    all = "",
    blog = "blog",
    caseHistory = "case-history",
    academy = "academy",
    events = "events",
    analysis = "analysis",
    onDemand = "on-demand",
    video = "video",
    podcast = "podcast",
}

export interface PostItem {
    date?: string | null | undefined
    title?: string | null | undefined
    category?: string | null | undefined
    type?: PostType | string | null | undefined
    tags?: Array<string | null | undefined> | null | undefined
    description_short?: string | null | undefined
    slug?: string | null | undefined
    image?: {gatsbyImageData?: any | null; title?: string | null | undefined} | null | undefined
    customer?: string | null | undefined
    project?: string | null | undefined
    pdf?: {url?: string | null; title?: string | null} | null | undefined
}
