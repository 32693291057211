import {Script} from "gatsby-script"

import {FC, HTMLAttributes} from "react"

type TagManagerProps = HTMLAttributes<HTMLDivElement>

const TagManager: FC<TagManagerProps> = props => {
    const {...otherProps} = props
    return (
        <>
            <Script id="tag-manager-1" async src="https://www.googletagmanager.com/gtag/js?id=G-ZH3VTHQMY2" />
            <Script id="tag-manager-2">{`
            window.dataLayer = window.dataLayer || []
            function gtag() {
                dataLayer.push(arguments)
            }
            gtag('js', new Date())
            gtag('config', 'G-ZH3VTHQMY2')
            `}</Script>
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=G-ZH3VTHQMY2"
                    height="0"
                    width="0"
                    style={{display: "none", visibility: "hidden"}}
                ></iframe>
            </noscript>
        </>
    )
}

export default TagManager
