import clsx from "clsx"
import React, {useCallback, useEffect, useState} from "react"
import ChevronUpIcon from "~/icons/ChevronUpIcon"
import "./BackToTop.css"

interface BackToTopProps extends React.HTMLAttributes<HTMLDivElement> {}

const BackToTop: React.FC<BackToTopProps> = props => {
    const {className, children, ...otherProps} = props
    const [visible, setVisible] = useState(false)

    const goBackToTop = useCallback(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    const handleBackToTopVisibility = useCallback(() => {
        const shouldBeVisible = document.documentElement.scrollTop > 100
        if (shouldBeVisible && !visible) {
            setVisible(true)
        }
        if (!shouldBeVisible && visible) {
            setVisible(false)
        }
    }, [])

    useEffect(() => {}, [])

    useEffect(() => {
        document.addEventListener("scroll", handleBackToTopVisibility)
        return () => {
            document.removeEventListener("scroll", handleBackToTopVisibility)
        }
    }, [])

    return (
        <div className={clsx("back-to-top-h2fr", visible && "visible", className)} {...otherProps}>
            <button className="btt-container-h2fr" onClick={goBackToTop}>
                <ChevronUpIcon className="icon-h2fr" />
                {children}
            </button>
        </div>
    )
}

export default BackToTop
