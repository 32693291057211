class log {
    static error = (message?: any, ...optionalParams: any[]) => {
        if (process.env.NODE_ENV !== "development") return
        console.log(message, ...optionalParams)
    }

    static warn = (message?: any, ...optionalParams: any[]) => {
        if (process.env.NODE_ENV !== "development") return
        console.log(message, ...optionalParams)
    }

    static info = (message?: any, ...optionalParams: any[]) => {
        if (process.env.NODE_ENV !== "development") return
        console.log(message, ...optionalParams)
    }
}

export default log
