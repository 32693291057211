const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg fill={fill ? fill : "currentColor"} viewBox={viewBox ? viewBox : "0 0 24 24 "} {...otherProps}>
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
        </svg>
    )
}

export default CheckIcon
