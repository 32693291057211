import {Script} from "gatsby-script"

import {FC, HTMLAttributes} from "react"

type LinkedInInsightTagProps = HTMLAttributes<HTMLDivElement>

const LinkedInInsightTag: FC<LinkedInInsightTagProps> = props => {
    const {...otherProps} = props
    return (
        <>
            <Script id="linkedin-pixel">{`
            _linkedin_partner_id = '1799697'
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
            window._linkedin_data_partner_ids.push(_linkedin_partner_id)
            ;(function (l) {
                if (!l) {
                    window.lintrk = function (a, b) {
                        window.lintrk.q.push([a, b])
                    }
                    window.lintrk.q = []
                }
                var s = document.getElementsByTagName('script')[0]
                var b = document.createElement('script')
                b.type = 'text/javascript'
                b.async = true
                b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
                s.parentNode.insertBefore(b, s)
            })(window.lintrk)
    `}</Script>
            <noscript>
                <img
                    height="1"
                    width="1"
                    style={{display: "none", visibility: "hidden"}}
                    alt=""
                    src="https://px.ads.linkedin.com/collect/?pid=1799697&fmt=gif"
                />
            </noscript>
        </>
    )
}

export default LinkedInInsightTag
