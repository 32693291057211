import clsx from "clsx"
import {graphql, useStaticQuery} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import React, {useContext} from "react"
import SubscribeForm from "~/components/Forms/SubscribeForm"
import GenericLink from "~/components/UI/GenericLink/GenericLink"
import SectionContainer from "~/components/UI/SectionContainer/SectionContainer"
import I18nContext from "~/contexts/I18nContext"
import NewsLetterIcon from "~/icons/NewsLetterIcon"
import {useLinksQuery} from "~/queries/LinksQuery"
import BackToTop from "../../UI/BackToTop/BackToTop"
import "./Footer.css"
import SiteMap from "./SiteMap"

interface FooterProps extends React.HTMLAttributes<HTMLElement> {}

const Footer: React.FC<FooterProps> = props => {
    const {className, ...otherProps} = props
    const i18n = useContext(I18nContext)
    const links = useLinksQuery()

    const data: Queries.FooterQuery = useStaticQuery(graphql`
        query Footer {
            allContentfulFooter {
                nodes {
                    address
                    legalInfo
                    companyName
                    name
                    node_locale
                    subscribeButton
                    subscribePrivateArea
                    newsletterEmailPlaceholder
                    sitemap {
                        about
                        academy
                        blog
                        case
                        contacts
                        creativeFormats
                        events
                        magazine
                        name
                        products
                        proximityMarketing
                        valueNetwork
                        governance
                        jobs
                        team
                        phoneNumber
                        email
                        glossary
                        ourFormats
                        whyChooseUse
                        ourPublishers
                        ourLastProjects
                        testimonials
                    }
                }
            }
        }
    `)

    let currentData = data.allContentfulFooter.nodes[0]!
    for (const footerTranslation of data.allContentfulFooter.nodes) {
        if (footerTranslation.node_locale === i18n.language) {
            currentData = footerTranslation
            break
        }
    }

    const onTabClickHandler = () => {
        const ref = document.getElementById("subscription-mail-input")
        if (ref) ref.focus()
    }

    return (
        <footer className={clsx("footer-tgt8", className)} {...otherProps}>
            <BackToTop />
            <SectionContainer
                fullWidth
                tabbed
                customTab={<NewsLetterIcon className="newsletter-icon-tgt8" />}
                onTabClick={onTabClickHandler}
                marginPre
            >
                <div className="footer-mw-1-tgt8">
                    <div className="footer-container-tgt8">
                        <div className="left-tgt8">
                            <GenericLink to={links.homepage.url} className="logo-tgt8">
                                <StaticImage
                                    src="../../../images/logo-white.png"
                                    alt="Logo"
                                    placeholder="blurred"
                                    height={40}
                                />
                                <div className="logo-text-tgt8">DIGITAL FIGHTERS</div>
                            </GenericLink>
                            <SubscribeForm className="newsletter-form-tgt8" />
                        </div>
                        <div className="right-tgt8">
                            <SiteMap data={currentData.sitemap} className="sitemap-tgt8" />
                        </div>
                    </div>
                </div>
                <div className="footer-mw-2-tgt8">
                    <div className="legal-info-tgt8">
                        {`${currentData.companyName} ® ${new Date().getFullYear()} - ${currentData.legalInfo} | ${
                            currentData.address
                        }`}
                    </div>
                </div>
            </SectionContainer>
        </footer>
    )
}

export default Footer
