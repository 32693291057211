import {graphql, useStaticQuery} from "gatsby"
import {useContext} from "react"
import {PostTypes} from "~/types/PostItem"
import I18nContext from "../contexts/I18nContext"
import {getSupportedLanguages} from "../utilities/languageUtilities"

interface StaticLinksGroup {
    homepage: StaticLink
    about: StaticLink
    products: StaticLink
    magazine: StaticLink
    privateArea: StaticLink
    contacts: StaticLink
    blog: StaticLink
    caseHistory: StaticLink
    academy: StaticLink
    events: StaticLink
    analysis: StaticLink
    onDemand: StaticLink
    video: StaticLink
    podcast: StaticLink
    authentication: StaticLink
    valueNetwork: StaticLink
}

interface StaticLink {
    url: string
    name: string
}

export const useLinksQuery = (): StaticLinksGroup => {
    const {language} = useContext(I18nContext)

    const data: Queries.StaticLinksQuery = useStaticQuery(graphql`
        query StaticLinks {
            allContentfulPaginaChiSiamo {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        titolo
                    }
                }
            }
            allContentfulPaginaContatti {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        titolo
                    }
                }
            }
            allContentfulPaginaMagazine {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        titolo
                    }
                }
            }
            allContentfulPaginaProdotti {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        title
                    }
                }
            }
            allContentfulPaginaAreaRiservata {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        titolo
                    }
                }
            }
            allContentfulPaginaHomepage {
                edges {
                    node {
                        node_locale
                        contentful_id
                        title
                    }
                }
            }
            allContentfulPaginaAutenticazione {
                edges {
                    node {
                        node_locale
                        contentful_id
                        title
                        slug
                    }
                }
            }
            allContentfulPaginaValueNetwork {
                edges {
                    node {
                        title
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
        }
    `)

    const links = new Map<string, StaticLinksGroup>()

    getSupportedLanguages().forEach(language => {
        let homepage: StaticLink = {url: "/" + language, name: ""}

        const blog: StaticLink = {url: "/" + language + "/" + PostTypes.blog, name: ""}
        const caseHistory: StaticLink = {url: "/" + language + "/" + PostTypes.caseHistory, name: ""}
        const academy: StaticLink = {url: "/" + language + "/" + PostTypes.academy, name: ""}
        const events: StaticLink = {url: "/" + language + "/" + PostTypes.events, name: ""}
        const analysis: StaticLink = {url: "/" + language + "/" + PostTypes.analysis, name: ""}
        const onDemand: StaticLink = {url: "/" + language + "/" + PostTypes.onDemand, name: ""}
        const video: StaticLink = {url: "/" + language + "/" + PostTypes.video, name: ""}
        const podcast: StaticLink = {url: "/" + language + "/" + PostTypes.podcast, name: ""}

        let about: StaticLink = {url: "", name: ""}
        let products: StaticLink = {url: "", name: ""}
        let magazine: StaticLink = {url: "", name: ""}
        let privateArea: StaticLink = {url: "", name: ""}
        let contacts: StaticLink = {url: "", name: ""}
        let authentication: StaticLink = {url: "", name: ""}
        let valueNetwork: StaticLink = {url: "", name: ""}

        data.allContentfulPaginaHomepage.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            homepage = {
                url: "/" + language,
                name: node.title ?? "",
            }
        })

        data.allContentfulPaginaAreaRiservata.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            privateArea = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.titolo ?? "",
            }
        })

        data.allContentfulPaginaChiSiamo.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            about = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.titolo ?? "",
            }
        })

        data.allContentfulPaginaContatti.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            contacts = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.titolo ?? "",
            }
        })

        data.allContentfulPaginaMagazine.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            magazine = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.titolo ?? "",
            }
        })

        data.allContentfulPaginaProdotti.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            products = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.title ?? "",
            }
        })

        data.allContentfulPaginaAutenticazione.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            authentication = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.title ?? "",
            }
        })

        data.allContentfulPaginaValueNetwork.edges.forEach(edge => {
            const node = edge.node
            if (edge.node.node_locale !== language) return
            valueNetwork = {
                url: "/" + language + "/" + (node.slug ?? ""),
                name: node.title ?? "",
            }
        })

        links.set(language, {
            homepage,
            about,
            contacts,
            magazine,
            privateArea,
            products,
            blog,
            caseHistory,
            academy,
            events,
            analysis,
            onDemand,
            video,
            podcast,
            authentication,
            valueNetwork,
        })
    })

    return links.get(language)!
}
