import {useLocation} from "@gatsbyjs/reach-router"
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    CookieStorage,
    IAuthenticationDetailsData,
    ICognitoStorage,
    ICognitoUserAttributeData,
    ICognitoUserData,
    ICognitoUserPoolData,
} from "amazon-cognito-identity-js"
import {ReactNode, createContext, useEffect, useRef, useState} from "react"
import log from "../utilities/log"

export type RequiredAttributes = {
    given_name?: string | undefined
    family_name?: string | undefined
    phone_number?: string | undefined
}

export type OptionalAttributes = {
    company_name?: string | undefined
    company_type?: string | undefined
    role?: string | undefined
}

export type EmailAttribute = {
    email?: string | undefined
}

export type AllAttributes = RequiredAttributes & OptionalAttributes

export type AllAttributesWithEmail = RequiredAttributes & OptionalAttributes & EmailAttribute

export enum AdKaoraUserFields {
    given_name = "given_name",
    family_name = "family_name",
    email = "email",
    phone_number = "phone_number",
    company_name = "custom:company_name",
    company_type = "custom:company_type",
    role = "custom:role",
}

const COUNTDOWN = 5000

interface AuthContextInterface {
    status: AuthState
    setStatus: (value: AuthState) => void
    signUp: (email: string, password: string, attributes: AllAttributes) => Promise<AuthState>
    confirmAccount: (confirmationCode: string) => Promise<AuthState>
    resendConfirmationCode: () => void
    signIn: (email: string, password: string) => Promise<AuthState>
    signOut: () => Promise<AuthState>
    changePassword: (oldPassword: string, newPassword: string) => Promise<AuthState>
    forgottenPassword: (email: string) => Promise<AuthState>
    forgottenPasswordConfirmNewPassword: (verificationCode: string, newPassword: string) => Promise<AuthState>
    attributes: AllAttributesWithEmail | undefined
    updateAttributes: (attributes: AllAttributes) => Promise<AuthState>
    deleteAccount: () => Promise<AuthState>
    resetPassword: (newPassword: string, attributes: AllAttributes) => Promise<AuthState>
    resetStatus: () => void
}

export const AuthDefaultContextValues: AuthContextInterface = {
    status: "INVALID_USER_POOL",
    setStatus: (value: AuthState) => {},
    signUp: (email: string, password: string, attributes: AllAttributes) => new Promise(() => "SIGNED_OUT"),
    confirmAccount: (confirmationCode: string) => new Promise(() => "SIGNED_OUT"),
    resendConfirmationCode: () => {},
    signIn: (email: string, password: string) => new Promise(() => "SIGNED_OUT"),
    signOut: () => new Promise(() => "SIGNED_OUT"),
    changePassword: (oldPassword: string, newPassword: string) => new Promise(() => "SIGNED_OUT"),
    forgottenPassword: (email: string) => new Promise(() => "SIGNED_OUT"),
    forgottenPasswordConfirmNewPassword: (verificationCode: string, newPassword: string) =>
        new Promise(() => "SIGNED_OUT"),
    attributes: undefined,
    updateAttributes: (attributes: AllAttributes) => new Promise(() => "SIGNED_OUT"),
    deleteAccount: () => new Promise(() => "SIGNED_OUT"),
    resetPassword: (newPassword: string, attributes: AllAttributes) => new Promise(() => "SIGNED_OUT"),
    resetStatus: () => {},
}

const AuthContext = createContext(AuthDefaultContextValues)

export type AuthState =
    | "INITIAL_STATE"
    | "INVALID_USER_POOL"
    | "SIGN_UP"
    | "SIGN_UP_ALREADY_REGISTERED"
    | "SIGN_UP_INVALID"
    | "SIGNED_OUT"
    | "SIGNED_OUT_INVALID"
    | "SIGNED_OUT_UPDATE_PASSWORD_SUCCESS"
    | "SIGNED_OUT_UPDATE_PASSWORD_FAIL"
    | "SIGNED_OUT_ACCOUNT_CONFIRMED"
    | "SIGNED_IN"
    | "SIGNED_IN_UPDATE_PASSWORD_SUCCESS"
    | "SIGNED_IN_UPDATE_PASSWORD_FAIL"
    | "NEW_PASSWORD_REQUIRED"
    | "CHANGE_PASSWORD"
    | "FORGOTTEN_PASSWORD"
    | "FORGOTTEN_PASSWORD_INVALID"
    | "FORGOTTEN_PASSWORD_VERIFICATION"
    | "FORGOTTEN_PASSWORD_VERIFICATION_INVALID"
    | "UPDATE_ATTRIBUTES"
    | "UPDATE_ATTRIBUTES_SUCCESS"
    | "UPDATE_ATTRIBUTES_FAIL"
    | "CONFIRMATION_NECESSARY"
    | "DELETE_ACCOUNT"
    | "DELETE_ACCOUNT_SUCCESS"
    | "DELETE_ACCOUNT_FAIL"
    | "GENERIC_ERROR"

export const AuthProvider: React.FC<{children: ReactNode}> = props => {
    const {children} = props
    const [status, setStatus] = useState<AuthState>("INITIAL_STATE")
    const cookieStorageRef = useRef<ICognitoStorage>()
    const userPoolRef = useRef<CognitoUserPool>()
    const userRef = useRef<CognitoUser | undefined | null>()
    const [attributes, setAttributes] = useState<AllAttributesWithEmail>()
    const location = useLocation()
    const timeoutRef = useRef<NodeJS.Timeout>()

    const getCookieStorage = () => {
        if (cookieStorageRef.current) return cookieStorageRef.current
        cookieStorageRef.current = new CookieStorage({domain: location.hostname})
        return cookieStorageRef.current
    }

    const getUserPool = () => {
        if (userPoolRef.current) return userPoolRef.current
        const poolData: ICognitoUserPoolData = {
            UserPoolId: `${process.env.AWS_COGNITO_USERS_POOL_ID}`,
            ClientId: `${process.env.AWS_COGNITO_CLIENT_ID}`,
            Storage: getCookieStorage(),
        }
        try {
            userPoolRef.current = new CognitoUserPool(poolData)
            setStatus("SIGNED_OUT")
        } catch (error) {
            setStatus("INVALID_USER_POOL")
        }
        return userPoolRef.current
    }

    const loadUser = () => {
        log.info("LOAD USER")
        const userPool = getUserPool()
        if (!userPool) return
        const user = setUser(userPool.getCurrentUser())
        if (!user) return
        user.getSession((error: Error | null, session: CognitoUserSession | null) => {
            if (error) {
                log.error(error)
                setStatus("SIGNED_OUT")
            }
            if (session) {
                log.info(session)
                getAttributes()
                setStatus("SIGNED_IN")
            }
        })
    }

    const getUser = () => {
        return userRef.current
    }

    const setUser = (newUser: CognitoUser | undefined | null) => {
        userRef.current = newUser
        return newUser
    }

    const setUserWithEmail = (email: string) => {
        const cookieStorage = getCookieStorage()
        const userPool = getUserPool()
        if (!userPool) return
        const userData: ICognitoUserData = {Username: email, Pool: userPool, Storage: cookieStorage}
        setUser(new CognitoUser(userData))
    }

    useEffect(() => {
        loadUser()
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [])

    const signUp = async (email: string, password: string, userAttributes: AllAttributes): Promise<AuthState> =>
        await new Promise(resolve => {
            const userPool = getUserPool()
            if (!userPool) {
                resolve("INVALID_USER_POOL")
                return
            }
            const [cognitoAttributes] = attributesToCognito(userAttributes)
            userPool.signUp(email, password, cognitoAttributes, cognitoAttributes, (error, result) => {
                if (error) {
                    if (error.name === "UsernameExistsException") {
                        log.error("SIGN_UP_ALREADY_REGISTERED")
                        setStatus("SIGN_UP_ALREADY_REGISTERED")
                        resolve("SIGN_UP_ALREADY_REGISTERED")
                    } else {
                        log.error("SIGN_UP_INVALID")
                        setStatus("SIGN_UP_INVALID")
                        resolve("SIGN_UP_INVALID")
                    }
                }
                if (result) {
                    setUser(result.user)
                    log.warn("CONFIRMATION_NECESSARY")
                    setStatus("CONFIRMATION_NECESSARY")
                    resolve("CONFIRMATION_NECESSARY")
                }
            })
        })

    const confirmAccount = async (confirmationCode: string): Promise<AuthState> =>
        await new Promise(resolve => {
            const user = getUser()
            if (!user) {
                resolve("SIGNED_OUT")
                return
            }
            user.confirmRegistration(confirmationCode, true, (error, result) => {
                if (error) {
                    log.info("GENERIC_ERROR")
                    setStatus("GENERIC_ERROR")
                    resolve("GENERIC_ERROR")
                    return
                }
                if (result) {
                    log.info("SIGNED_OUT_ACCOUNT_CONFIRMED")
                    setStatus("SIGNED_OUT_ACCOUNT_CONFIRMED")
                    resolve("SIGNED_OUT_ACCOUNT_CONFIRMED")
                }
            })
        })

    const resendConfirmationCode = () => {
        const user = getUser()
        if (!user) return
        user.resendConfirmationCode((err, result) => {})
    }

    const signIn = async (email: string, password: string): Promise<AuthState> =>
        await new Promise(resolve => {
            const cookieStorage = getCookieStorage()
            const userPool = getUserPool()
            if (!userPool) {
                log.error("INVALID_USER_POOL")
                resolve("INVALID_USER_POOL")
                return
            }
            const authenticationData: IAuthenticationDetailsData = {Username: email, Password: password}
            const authenticationDetails = new AuthenticationDetails(authenticationData)
            const userData: ICognitoUserData = {Username: email, Pool: userPool, Storage: cookieStorage}
            const user = setUser(new CognitoUser(userData))
            if (!user) {
                log.error("SIGNED_OUT")
                resolve("SIGNED_OUT")
                return
            }
            user.authenticateUser(authenticationDetails, {
                onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean | undefined) => {
                    if (userConfirmationNecessary) {
                        setStatus("CONFIRMATION_NECESSARY")
                        log.warn("CONFIRMATION_NECESSARY")
                        resolve("CONFIRMATION_NECESSARY")
                    } else {
                        getAttributes()
                        setStatus("SIGNED_IN")
                        log.info("SIGNED_IN")
                        resolve("SIGNED_IN")
                    }
                },
                onFailure: (error: Error) => {
                    switch (error.name) {
                        case "UserNotConfirmedException":
                            setStatus("CONFIRMATION_NECESSARY")
                            log.warn("CONFIRMATION_NECESSARY", error)
                            resolve("CONFIRMATION_NECESSARY")
                            break
                        default:
                            setStatus("SIGNED_OUT_INVALID")
                            log.info("SIGNED_OUT_INVALID", error)
                            resolve("SIGNED_OUT_INVALID")
                    }
                },
                newPasswordRequired: (
                    userAttributes: Array<CognitoUserAttribute>,
                    requiredAttributes: Array<string>,
                ) => {
                    setStatus("NEW_PASSWORD_REQUIRED")
                    log.warn("NEW_PASSWORD_REQUIRED")
                    resolve("NEW_PASSWORD_REQUIRED")
                },
            })
        })

    const signOut = async (): Promise<AuthState> => {
        return new Promise(resolve => {
            const user = getUser()
            if (user) {
                user.signOut()
            }
            setUser(undefined)
            setStatus("SIGNED_OUT")
            log.info("SIGNED_OUT")
            resolve("SIGNED_OUT")
        })
    }

    const changePassword = async (oldPassword: string, newPassword: string): Promise<AuthState> => {
        return new Promise(resolve => {
            const user = getUser()
            if (!user) {
                log.error("NO USER")
                resolve("SIGNED_OUT")
                return
            }
            user.changePassword(oldPassword, newPassword, function (err, result) {
                if (err) {
                    setStatus("SIGNED_IN_UPDATE_PASSWORD_FAIL")
                    log.error("SIGNED_IN_UPDATE_PASSWORD_FAIL")
                    resolve("SIGNED_IN_UPDATE_PASSWORD_FAIL")
                }
                if (result) {
                    getAttributes()
                    setStatus("SIGNED_IN_UPDATE_PASSWORD_SUCCESS")
                    log.info("SIGNED_IN_UPDATE_PASSWORD_SUCCESS")
                    resolve("SIGNED_IN_UPDATE_PASSWORD_SUCCESS")
                }
            })
        })
    }

    const forgottenPassword = async (email: string): Promise<AuthState> => {
        return new Promise(resolve => {
            setUserWithEmail(email)
            const user = getUser()
            if (!user) {
                log.info("SIGNED_OUT")
                resolve("SIGNED_OUT")
                return
            }
            user.forgotPassword({
                onSuccess: data => {
                    setStatus("FORGOTTEN_PASSWORD_VERIFICATION")
                    log.info("FORGOTTEN_PASSWORD_VERIFICATION")
                    resolve("FORGOTTEN_PASSWORD_VERIFICATION")
                },
                onFailure: function (err) {
                    setStatus("FORGOTTEN_PASSWORD_INVALID")
                    log.error("FORGOTTEN_PASSWORD_INVALID")
                    resolve("FORGOTTEN_PASSWORD_INVALID")
                },
                inputVerificationCode: data => {
                    setStatus("FORGOTTEN_PASSWORD_VERIFICATION")
                    log.info("FORGOTTEN_PASSWORD_VERIFICATION")
                    resolve("FORGOTTEN_PASSWORD_VERIFICATION")
                },
            })
        })
    }

    const forgottenPasswordConfirmNewPassword = async (
        verificationCode: string,
        newPassword: string,
    ): Promise<AuthState> => {
        return new Promise(resolve => {
            const user = getUser()
            if (!user) {
                resolve("SIGNED_OUT")
                return
            }
            user.confirmPassword(verificationCode, newPassword, {
                onSuccess(success: string) {
                    setStatus("SIGNED_OUT_UPDATE_PASSWORD_SUCCESS")
                    log.info("SIGNED_OUT_UPDATE_PASSWORD_SUCCESS")
                    resolve("SIGNED_OUT_UPDATE_PASSWORD_SUCCESS")
                },
                onFailure(err) {
                    setStatus("FORGOTTEN_PASSWORD_VERIFICATION_INVALID")
                    log.error("FORGOTTEN_PASSWORD_VERIFICATION_INVALID")
                    resolve("FORGOTTEN_PASSWORD_VERIFICATION_INVALID")
                },
            })
        })
    }

    const resetPassword = async (newPassword: string, userAttributes: AllAttributes): Promise<AuthState> => {
        return await new Promise(resolve => {
            const user = getUser()
            if (!user) {
                resolve("SIGNED_OUT")
                return
            }
            const requiredAttributes = getRequiredAttributes(userAttributes)

            const [optionalAttributes] = attributesToCognito(getOptionalAttributes(userAttributes))

            user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
                onSuccess: (session, userConfirmationNecessary?) => {
                    if (userConfirmationNecessary) {
                        log.warn("CONFIRMATION_NECESSARY")
                        setStatus("CONFIRMATION_NECESSARY")
                        resolve("CONFIRMATION_NECESSARY")
                    } else {
                        log.info("SIGNED_IN")
                        getAttributes()
                        setStatus("SIGNED_IN")
                        resolve("SIGNED_IN")
                    }
                    user.updateAttributes(optionalAttributes, (err, result) => {})
                },
                onFailure: error => {
                    log.error("GENERIC_ERROR", error)
                    setStatus("GENERIC_ERROR")
                    resolve("GENERIC_ERROR")
                },
            })
        })
    }

    const getAttributes = async (): Promise<AllAttributesWithEmail | undefined> => {
        log.info("read attributes")
        return new Promise(resolve => {
            const user = getUser()
            if (!user) {
                log.error("NO USER")
                resolve(undefined)
                return
            }
            user.getUserAttributes(function (error, attributes) {
                if (error) {
                    log.error("error retrieving attributes", error)
                    resolve(undefined)
                    return
                } else {
                    if (!attributes) {
                        log.error("No Attributes")
                        resolve(undefined)
                        return
                    }
                    const tempUserAttributes: AllAttributesWithEmail = {}
                    attributes.forEach(attribute => {
                        switch (attribute.Name) {
                            case AdKaoraUserFields.email:
                                tempUserAttributes.email = attribute.Value
                                break
                            case AdKaoraUserFields.given_name:
                                tempUserAttributes.given_name = attribute.Value
                                break
                            case AdKaoraUserFields.family_name:
                                tempUserAttributes.family_name = attribute.Value
                                break
                            case AdKaoraUserFields.phone_number:
                                tempUserAttributes.phone_number = attribute.Value
                                break
                            case AdKaoraUserFields.company_name:
                                tempUserAttributes.company_name = attribute.Value
                                break
                            case AdKaoraUserFields.company_type:
                                tempUserAttributes.company_type = attribute.Value
                                break
                            case AdKaoraUserFields.role:
                                tempUserAttributes.role = attribute.Value
                                break
                        }
                    })
                    setAttributes(tempUserAttributes)
                    resolve(tempUserAttributes)
                }
            })
        })
    }

    const updateAttributes = async (userAttributes: AllAttributes): Promise<AuthState> =>
        await new Promise(resolve => {
            const user = getUser()
            if (!user) {
                log.error("NO USER")
                resolve("SIGNED_OUT")
                return
            }

            const [attributesToUpdate, attributesToDelete] = attributesToCognito(userAttributes)

            user.deleteAttributes(attributesToDelete, (error, result) => {})
            user.updateAttributes(attributesToUpdate, (error, result) => {
                if (error) {
                    log.error("UPDATE_ATTRIBUTES_FAIL", error)
                    setStatus("UPDATE_ATTRIBUTES_FAIL")
                    resolve("UPDATE_ATTRIBUTES_FAIL")
                }
                if (result) {
                    getAttributes()
                    log.info("UPDATE_ATTRIBUTES_SUCCESS")
                    setStatus("UPDATE_ATTRIBUTES_SUCCESS")
                    resolve("UPDATE_ATTRIBUTES_SUCCESS")
                }
            })
        })

    const deleteAccount = async (): Promise<AuthState> =>
        await new Promise(resolve => {
            const user = getUser()
            if (!user) {
                log.error("NO USER")
                resolve("SIGNED_OUT")
                return
            }
            user.deleteUser((error, result) => {
                if (error) {
                    log.error("DELETE_ACCOUNT_FAIL", error)
                    setStatus("DELETE_ACCOUNT_FAIL")
                    resolve("DELETE_ACCOUNT_FAIL")
                }
                if (result) {
                    log.warn("DELETE_ACCOUNT_SUCCESS")
                    setStatus("DELETE_ACCOUNT_SUCCESS")
                    resolve("DELETE_ACCOUNT_SUCCESS")
                }
            })
        })

    const resetStatus = () => {
        timeoutRef.current = setTimeout(() => {
            switch (status) {
                case "DELETE_ACCOUNT_SUCCESS":
                    setStatus("SIGNED_OUT")
                    break
                case "SIGNED_IN_UPDATE_PASSWORD_FAIL":
                    setStatus("SIGNED_IN")
                    break
                case "SIGNED_IN_UPDATE_PASSWORD_SUCCESS":
                    setStatus("SIGNED_IN")
                    break
                case "SIGNED_OUT_UPDATE_PASSWORD_FAIL":
                    setStatus("SIGNED_OUT")
                    break
                case "SIGNED_OUT_UPDATE_PASSWORD_SUCCESS":
                    setStatus("SIGNED_OUT")
                    break
                case "UPDATE_ATTRIBUTES_FAIL":
                    setStatus("SIGNED_IN")
                    break
                case "UPDATE_ATTRIBUTES_SUCCESS":
                    setStatus("SIGNED_IN")
                    break
                case "DELETE_ACCOUNT_FAIL":
                    setStatus("SIGNED_OUT")
                    break
            }
        }, COUNTDOWN)
    }

    return (
        <AuthContext.Provider
            value={{
                status,
                setStatus,
                signUp,
                confirmAccount,
                resendConfirmationCode,
                signIn,
                signOut,
                changePassword,
                forgottenPassword,
                forgottenPasswordConfirmNewPassword,
                attributes,
                updateAttributes,
                deleteAccount,
                resetPassword,
                resetStatus,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext

const attributesToCognito = (attributes: AllAttributes): [Array<CognitoUserAttribute>, Array<string>] => {
    const userAttributes: Array<CognitoUserAttribute> = []
    const emptyAttributes: Array<string> = []

    if (attributes.given_name && attributes.given_name.trim() !== "") {
        const data: ICognitoUserAttributeData = {
            Name: AdKaoraUserFields.given_name,
            Value: attributes.given_name,
        }
        const attribute: CognitoUserAttribute = new CognitoUserAttribute(data)
        userAttributes.push(attribute)
    } else {
        emptyAttributes.push(AdKaoraUserFields.given_name)
    }
    if (attributes.family_name && attributes.family_name.trim() !== "") {
        const data: ICognitoUserAttributeData = {
            Name: AdKaoraUserFields.family_name,
            Value: attributes.family_name,
        }
        const attribute: CognitoUserAttribute = new CognitoUserAttribute(data)
        userAttributes.push(attribute)
    } else {
        emptyAttributes.push(AdKaoraUserFields.family_name)
    }
    if (attributes.phone_number && attributes.phone_number.trim() !== "") {
        const data: ICognitoUserAttributeData = {
            Name: AdKaoraUserFields.phone_number,
            Value: attributes.phone_number,
        }
        const attribute: CognitoUserAttribute = new CognitoUserAttribute(data)
        userAttributes.push(attribute)
    } else {
        emptyAttributes.push(AdKaoraUserFields.phone_number)
    }
    if (attributes.company_name && attributes.company_name.trim() !== "") {
        const data: ICognitoUserAttributeData = {
            Name: AdKaoraUserFields.company_name,
            Value: attributes.company_name,
        }
        const attribute: CognitoUserAttribute = new CognitoUserAttribute(data)
        userAttributes.push(attribute)
    } else {
        emptyAttributes.push(AdKaoraUserFields.company_name)
    }
    if (attributes.company_type && attributes.company_type.trim() !== "") {
        const data: ICognitoUserAttributeData = {
            Name: AdKaoraUserFields.company_type,
            Value: attributes.company_type,
        }
        const attribute: CognitoUserAttribute = new CognitoUserAttribute(data)
        userAttributes.push(attribute)
    } else {
        emptyAttributes.push(AdKaoraUserFields.company_type)
    }
    if (attributes.role && attributes.role.trim() !== "") {
        const data: ICognitoUserAttributeData = {
            Name: AdKaoraUserFields.role,
            Value: attributes.role,
        }
        const attribute: CognitoUserAttribute = new CognitoUserAttribute(data)
        userAttributes.push(attribute)
    } else {
        emptyAttributes.push(AdKaoraUserFields.role)
    }
    return [userAttributes, emptyAttributes]
}

const getRequiredAttributes = (attributes: AllAttributes): RequiredAttributes => {
    return (({given_name, family_name, phone_number}) => ({
        given_name,
        family_name,
        phone_number,
    }))(attributes)
}
const getOptionalAttributes = (attributes: AllAttributes): OptionalAttributes => {
    return (({company_name, company_type, role}) => ({
        company_name,
        company_type,
        role,
    }))(attributes)
}
