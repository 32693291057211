import clsx from "clsx"
import {forwardRef, useState} from "react"
import HiddenIcon from "~/icons/HiddenIcon"
import VisibleIcon from "~/icons/VisibleIcon"
import "./InputText.css"

type InputTextVariant = "default" | "soft" | "soft-filled" | "footer"

interface InputTextProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "placeholder"> {
    containerClassName?: string | undefined
    placeholder?: string | null | undefined
    invalid?: boolean
    variant?: InputTextVariant
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(function InputText(props: InputTextProps, ref) {
    const {className, type, invalid, variant = "default", placeholder, containerClassName, ...otherProps} = props
    const [visible, setVisible] = useState(false)

    const _type: React.InputHTMLAttributes<HTMLInputElement>["type"] =
        type === "password" && visible === false ? "password" : "text"

    return (
        <div className={clsx("input-container-ume4", containerClassName)}>
            <input
                className={clsx("input-text-ume4", variant, {"invalid-ume4": invalid}, className)}
                ref={ref}
                type={_type}
                placeholder={placeholder ? placeholder : ""}
                {...otherProps}
            />
            {type === "password" && (
                <button className="button-ume4" type="button" onClick={() => setVisible(state => !state)}>
                    {visible ? (
                        <HiddenIcon className="password-visibility-icon-ume4" />
                    ) : (
                        <VisibleIcon className="password-visibility-icon-ume4" />
                    )}
                </button>
            )}
        </div>
    )
})

export default InputText
