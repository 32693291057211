const MenuIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg
            viewBox={viewBox ? viewBox : "0 0 24 24"}
            fill={fill ? fill : "currentColor"}
            stroke={stroke ? stroke : "currentColor"}
            {...otherProps}
        >
            <path stroke="none" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </svg>
    )
}

export default MenuIcon
