const ErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg fill={fill ? fill : "currentColor"} viewBox={viewBox ? viewBox : "0 0 24 24 "} {...otherProps}>
            <path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        </svg>
    )
}

export default ErrorIcon
