import {navigate} from "@reach/router"
import clsx from "clsx"
import {GatsbyLinkProps, Link} from "gatsby"
import {Children, useContext} from "react"
import TransitionContext from "~/contexts/TransitionContext"
import "./GenericLink.css"

interface GenericLinkProps extends Omit<GatsbyLinkProps<any>, "to"> {
    withUnderline?: boolean | undefined
    withoutTransition?: boolean | undefined
    to?: string | undefined | null
}

const GenericLink: React.FC<GenericLinkProps> = props => {
    const {
        children,
        withoutTransition = false,
        className,
        to = "",
        download,
        ref,
        onClick,
        withUnderline,
        ...otherProps
    } = props
    const transitionContenxt = useContext(TransitionContext)

    const internal = to ? /^\/(?!\/)/.test(to) : false
    const isString = Children.toArray(children).length === 1 && typeof Children.toArray(children)[0] === "string"

    const goTo = (url: string) => {
        if (withoutTransition) {
            if (url) navigate(url)
            return
        }
        if (url) transitionContenxt.navigateTo(url)
    }

    const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        if (onClick) onClick(event)
        goTo(event.currentTarget.href)
    }

    return internal ? (
        <Link
            className={clsx("generic-link-hb2c", isString || withUnderline ? "text-hb2c" : "", className)}
            to={to ?? ""}
            download={download}
            // {...(download ? { target: "_self" } : { target: "_blank" })}
            target="_self"
            onClick={onClickHandler}
            {...otherProps}
        >
            {children}
        </Link>
    ) : (
        <a
            href={to ?? ""}
            className={clsx("generic-link-hb2c", isString || withUnderline ? "text-hb2c" : "", className)}
            download={download}
            {...(download ? {target: "_self"} : {target: "_blank"})}
            {...otherProps}
        >
            {children}
        </a>
    )
}

export default GenericLink
