export const nameRegex = /^[a-zA-Z' ]{3,20}$/
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,20}$/
export const phoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/
export const phoneRegexZoho = /^[0-9]+$/
export const cognitoCodeRegex = /^[0-9]{1,6}$/
export const textRegex = /^[a-zA-Z' ]{5,10000}$/
export const urlRegex =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/
export const numberRegex = /^-?\d+$/
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
