import {useEffect, useState} from "react"

const useNextRoute = () => {
    const [nextRoute, setNextRoute] = useState("")

    const interceptRouteChange = (event: Event) => {
        requestAnimationFrame(() => {
            if (nextRoute !== location.href) {
                setNextRoute(location.href)
            }
        })
    }

    useEffect(() => {
        document.body.addEventListener("click", interceptRouteChange, true)
        return () => {
            document.body.removeEventListener("click", interceptRouteChange, true)
        }
    }, [])

    return nextRoute
}

export default useNextRoute
