import {useLocation} from "@gatsbyjs/reach-router"
import {graphql, useStaticQuery} from "gatsby"

export const useLinkTranslatorQuery = (language: string): string => {
    const location = useLocation()
    const currentUrl = location.pathname

    const data: Queries.LinkTranslatorQuery = useStaticQuery(graphql`
        query LinkTranslator {
            allContentfulPaginaChiSiamo {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
            allContentfulPaginaContatti {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
            allContentfulPaginaMagazine {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
            allContentfulPaginaProdotti {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
            allContentfulPaginaAreaRiservata {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
            allContentfulContenutoAreaRiservata {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        type
                    }
                }
            }
            allContentfulContenutoCaseHistory {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        type
                    }
                }
            }
            allContentfulContenutoMagazine {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                        type
                    }
                }
            }
            allContentfulPaginaAutenticazione {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
            allContentfulPaginaValueNetwork {
                edges {
                    node {
                        slug
                        node_locale
                        contentful_id
                    }
                }
            }
        }
    `)

    const allLinks: Array<LocalizedLink> = []

    data.allContentfulContenutoAreaRiservata.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.type + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulContenutoCaseHistory.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.type + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulContenutoMagazine.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.type + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaAreaRiservata.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaChiSiamo.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaContatti.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaMagazine.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaProdotti.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaAutenticazione.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    data.allContentfulPaginaValueNetwork.edges.forEach(edge => {
        const node = edge.node
        const url = "/" + node.node_locale + "/" + node.slug
        const newLink: LocalizedLink = {id: node.contentful_id, language: node.node_locale, url: url}
        allLinks.push(newLink)
    })

    const getLinkByLanguage = (language: string): string => {
        let currentId: string = ""
        let result: string = `/${language}`

        for (const link of allLinks) {
            if (`${process.env.PATH_PREFIX}${link.url}` === currentUrl) {
                currentId = link.id
                break
            }
        }

        for (const link of allLinks) {
            if (link.url && link.id === currentId && link.language === language) {
                result = link.url
                break
            }
        }

        return result
    }

    return getLinkByLanguage(language)
}

interface LocalizedLink {
    id: string
    language: string
    url: string | null | undefined
}
