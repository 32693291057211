import clsx from "clsx"
import {PageProps} from "gatsby"
import React, {useContext, useEffect, useState} from "react"
import TransitionContext from "~/contexts/TransitionContext"
import "~/styles/style.css"
import "~/styles/typography.css"
import I18nContext from "../contexts/I18nContext"
import "./Layout.css"
import {default as NavBar} from "./NavBar/NavBar"
import NavMenu from "./NavMenu/NavMenu"
import Footer from "./Sections/Footer/Footer"
import FacebookPixel from "./UI/FacebookPixel/FacebookPixel"
import CookieSolution from "./UI/Iubenda/CookieSolution"
import LinkedInInsightTag from "./UI/LinkedInInsightTag/LinkedInInsightTag"
import TagManager from "./UI/TagManager/TagManager"

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement>, Omit<PageProps, "children"> {
    history?: History | undefined
}

const Layout: React.FC<LayoutProps> = props => {
    const {
        className,
        children,
        serverData,
        data,
        location,
        pageContext,
        pageResources,
        params,
        path,
        uri,
        ...otherProps
    } = props
    const [menuVisible, setMenuVisible] = useState(false)
    const transitionContenxt = useContext(TransitionContext)
    const {setLanguage} = useContext(I18nContext)

    const showMenu = () => {
        setMenuVisible(true)
    }

    const hideMenu = () => {
        setMenuVisible(false)
    }

    useEffect(() => {
        if (!transitionContenxt.isLoading) setMenuVisible(false)
    }, [transitionContenxt.isLoading])

    if (typeof window === "undefined") {
        const currentLanguage = location.pathname.split("/").at(1) ?? process.env.DEFAULT_LANGUAGE
        setLanguage(currentLanguage)
    }

    return (
        <div className={clsx("layout-6jam", className)} {...otherProps}>
            {/* <BackToTop /> */}
            <TagManager />
            <FacebookPixel />
            <LinkedInInsightTag />
            <NavMenu visible={!transitionContenxt.isLoading && menuVisible} onClose={hideMenu} />
            <NavBar onMenuIconClick={showMenu} />
            {children}
            <Footer />
            <CookieSolution />
        </div>
    )
}

export default Layout
