import {graphql, useStaticQuery} from "gatsby"

export const useSocialNetworksQuery = () => {
    const data: Queries.SocialNetworksQuery = useStaticQuery(graphql`
        query SocialNetworks {
            contentfulSocialNetworks {
                linkedInLink
                twitterLink
                facebookLink
                twitterDefaultHandleSite
                instagramLink
            }
        }
    `)

    return data.contentfulSocialNetworks
}
