import clsx from "clsx"
import {forwardRef, useState} from "react"
import CheckIcon from "~/icons/CheckIcon"
import "./CheckBox.css"

interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string | undefined | null
    invalid?: boolean
    containerProps?: React.HTMLAttributes<HTMLSpanElement> | undefined
}

const CheckBox: React.FC<CheckBoxProps> = forwardRef<HTMLInputElement, CheckBoxProps>((props, ref) => {
    const {className, containerProps, label, invalid, children, ...otherProps} = props
    const [enabled, setEnabled] = useState(false)
    return (
        <span className={clsx("checkbox-container-9n5q", className)} {...containerProps}>
            <span className={clsx("checkbox-9n5q", enabled ? "enabled-9n5q" : "", invalid ? "invalid-9n5q" : "")}>
                <input
                    className="checkbox-input-9n5q"
                    type="checkbox"
                    ref={ref}
                    aria-checked={enabled}
                    role="checkbox"
                    onClick={e => setEnabled(state => !state)}
                    checked={enabled}
                    {...otherProps}
                />
                <CheckIcon className="check-icon-9n5q" />
            </span>
            {label && <span className="label-9n5q">{label}</span>}
        </span>
    )
})

export default CheckBox
