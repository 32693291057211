import {useLocation} from "@gatsbyjs/reach-router"
import {ReactNode, createContext, useEffect, useState} from "react"

interface I18nContextInterface {
    language: string
    setLanguage: (value: string) => void
    supportedLaguages: Array<string>
}

const DEFAULT_LANGUAGE = `${process.env.DEFAULT_LANGUAGE || "it"}`
const SUPPORTED_LANGUAGES = `${process.env.SUPPORTED_LANGUAGES || DEFAULT_LANGUAGE}`.split(",")
const PATH_PREFIX = `${process.env.PATH_PREFIX}`

export const I18nDefaultContextValues: I18nContextInterface = {
    language: DEFAULT_LANGUAGE,
    setLanguage: (value: string) => {},
    supportedLaguages: [],
}

const I18nContext = createContext(I18nDefaultContextValues)

export const I18nProvider: React.FC<{children: ReactNode}> = props => {
    const {children} = props
    const [language, setLanguage] = useState(DEFAULT_LANGUAGE)
    const location = useLocation()
    const prefixOffset = PATH_PREFIX.match(/\//g)?.length || 0

    useEffect(() => {
        const currentUrl = new URL(location.href)
        const parsedLanguage = currentUrl.pathname.split("/")[1 + prefixOffset]
        if (!parsedLanguage || SUPPORTED_LANGUAGES.indexOf(parsedLanguage) < 0) {
            setLanguage(DEFAULT_LANGUAGE)
            return
        }
        setLanguage(parsedLanguage)
    }, [location.href, prefixOffset, language, setLanguage])

    return (
        <I18nContext.Provider
            value={{
                language,
                setLanguage,
                supportedLaguages: SUPPORTED_LANGUAGES,
            }}
        >
            {children}
        </I18nContext.Provider>
    )
}

export default I18nContext
