const ChevronUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg fill={fill ? fill : "currentColor"} viewBox={viewBox ? viewBox : "0 0 48 48 "} {...otherProps}>
            <path d="M14.15 30.75 12 28.6 24 16.6 36 28.55 33.85 30.7 24 20.85Z" />
        </svg>
    )
}

export default ChevronUpIcon
