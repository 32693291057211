import {Children, cloneElement, isValidElement} from "react"
import "./Ripple.css"

export type RippleColor = "green" | "light-green" | "gray" | "red" | "blue"

interface RippleProps {
    color?: RippleColor
    children: React.ReactNode
}

const Ripple = (props: RippleProps) => {
    const {color = "green", children} = props

    const drawRipple = (event: React.MouseEvent<HTMLElement>) => {
        const element = event.currentTarget
        const prevPosition = element.style.position
        const prevOverflow = element.style.overflow
        element.style.position = "relative"
        element.style.overflow = "hidden"

        const x = event.clientX - element.getBoundingClientRect().left
        const y = event.clientY - element.getBoundingClientRect().top

        const effect = document.createElement("div")
        effect.style.top = `${y}px`
        effect.style.left = `${x}px`
        effect.classList.add("ripple-effect")
        switch (color) {
            case "green":
                effect.classList.add("green")
                break
            case "light-green":
                effect.classList.add("light-green")
                break
            case "red":
                effect.classList.add("red")
                break
            case "gray":
                effect.classList.add("gray")
                break
            case "blue":
                effect.classList.add("blue")
                break
        }
        element.appendChild(effect)
        effect.onanimationend = () => {
            element.removeChild(effect)
            element.style.position = prevPosition
            element.style.overflow = prevOverflow
        }
    }

    return (
        <>
            {Children.map(children, child => {
                if (!isValidElement<React.HTMLAttributes<HTMLElement>>(child)) return child
                return cloneElement<React.HTMLAttributes<HTMLElement>>(child, {
                    onClick: event => {
                        drawRipple(event)
                        if (child.props.onClick) child.props.onClick(event)
                    },
                })
            })}
        </>
    )
}

export default Ripple
