import clsx from "clsx"
import {MouseEvent, useContext} from "react"
import Ripple, {RippleColor} from "~/components/UI/Ripple/Ripple"
import NavBarContext from "../../../contexts/NavBarContext"
import LoadingIcon from "../../../icons/LoadingIcon"

import "./Button.css"

export type ButtonColor = "white" | "green" | "gray" | "transparent" | "link" | "red" | "blue"

export interface ButtonProps {
    color?: ButtonColor
    loading?: boolean | undefined
}

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = props => {
    const {color = "green", onClick, type = "button", children, loading, className, ...otherProps} = props
    const navbarContext = useContext(NavBarContext)

    let rippleColor: RippleColor = "light-green"
    switch (color) {
        case "green":
            rippleColor = "light-green"
            break
        case "white":
            rippleColor = "green"
            break
        case "blue":
            rippleColor = "blue"
            break
        case "gray":
            rippleColor = "gray"
            break
        case "red":
            rippleColor = "red"
            break
    }

    const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
        navbarContext.hide(1000)
        if (onClick) onClick(event)
    }

    const button = (
        <button
            className={clsx("button-8p4x", `${color}-8p4x`, className)}
            type={type}
            onClick={onClickHandler}
            {...otherProps}
        >
            {loading && <LoadingIcon className="button-loading-8p4x" />}
            {children}
        </button>
    )

    if (color === "transparent" || color === "link") return button

    return <Ripple color={rippleColor}>{button}</Ripple>
}

export default Button
