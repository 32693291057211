export const getLanguageFromURL = () => {
    const PATH_PREFIX = `${process.env.PATH_PREFIX}`
    const currentUrl = new URL(location.href)
    const prefixOffset = PATH_PREFIX.match(/\//g)?.length || 0
    const parsedLanguage = currentUrl.pathname.split("/")[1 + prefixOffset]
    return parsedLanguage ?? getDefaultLanguage()
}

export const getDefaultLanguage = () => {
    return `${process.env.DEFAULT_LANGUAGE || "it"}`
}

export const getSupportedLanguages = () => {
    return `${process.env.SUPPORTED_LANGUAGES || process.env.DEFAULT_LANGUAGE}`.split(",")
}
