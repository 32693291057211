import clsx from "clsx"
import GenericLink from "~/components/UI/GenericLink/GenericLink"
import SocialLinks from "~/components/UI/SocialLinks/SocialLinks"
import {useLinksQuery} from "~/queries/LinksQuery"
import PrivacyPolicy from "../../UI/Iubenda/PrivacyPolicy"
import "./SiteMap.css"

interface SitemapTree {
    readonly about: string | null
    readonly academy: string | null
    readonly blog: string | null
    readonly case: string | null
    readonly contacts: string | null
    readonly creativeFormats: string | null
    readonly events: string | null
    readonly magazine: string | null
    readonly name: string | null
    readonly products: string | null
    readonly proximityMarketing: string | null
    readonly valueNetwork: string | null
    readonly governance: string | null
    readonly jobs: string | null
    readonly team: string | null
    readonly phoneNumber: string | null
    readonly email: string | null
    readonly glossary: string | null
    readonly ourFormats: string | null
    readonly whyChooseUse: string | null
    readonly ourPublishers: string | null
    readonly ourLastProjects: string | null
    readonly testimonials: string | null
}

interface SiteMapProps extends React.HTMLAttributes<HTMLUListElement> {
    data: SitemapTree | undefined | null
}

const SiteMap: React.FC<SiteMapProps> = props => {
    const {className, data, ...otherProps} = props
    const links = useLinksQuery()

    return (
        <>
            <ul className={clsx("sitemap-gck6", className)} {...otherProps}>
                <li>
                    <GenericLink to={links.about.url} className="list-title-gck6">
                        {data?.about}
                    </GenericLink>
                    <ul className="sub-list-gck6">
                        <li>
                            <GenericLink to={`${links.about.url}#governance`} className="sub-list-title-gck6">
                                {data?.governance}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`${links.about.url}#team`} className="sub-list-title-gck6">
                                {data?.team}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`${links.about.url}#careers`} className="sub-list-title-gck6">
                                {data?.jobs}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink
                                to={`https://marketing.adkaora.space/AdKaora_${data?.glossary}.pdf`}
                                className="sub-list-title-gck6"
                            >
                                {data?.glossary}
                            </GenericLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <GenericLink to={links.products.url} className="list-title-gck6">
                        {data?.products}
                    </GenericLink>
                    <ul className="sub-list-gck6">
                        <li>
                            <GenericLink
                                to={`${links.products.url}#proximity-marketing`}
                                className="sub-list-title-gck6"
                            >
                                {data?.proximityMarketing}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`${links.products.url}#creative-formats`} className="sub-list-title-gck6">
                                {data?.creativeFormats}
                            </GenericLink>
                        </li>
                        {/* <li>
                            <GenericLink to={`${links.products.url}#value-network`} className="sub-list-title-gck6">
                                {data?.valueNetwork}
                            </GenericLink>
                        </li> */}
                    </ul>
                </li>
                <li>
                    <GenericLink to={links.valueNetwork.url} className="list-title-gck6">
                        {data?.valueNetwork}
                    </GenericLink>
                    <ul className="sub-list-gck6">
                        <li>
                            <GenericLink to={`${links.valueNetwork.url}#our-formats`} className="sub-list-title-gck6">
                                {data?.ourFormats}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink
                                to={`${links.valueNetwork.url}#our-publishers`}
                                className="sub-list-title-gck6"
                            >
                                {data?.ourPublishers}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink
                                to={`${links.valueNetwork.url}#our-last-projects`}
                                className="sub-list-title-gck6"
                            >
                                {data?.ourLastProjects}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`${links.valueNetwork.url}#testimonials`} className="sub-list-title-gck6">
                                {data?.testimonials}
                            </GenericLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <GenericLink to={`${links.magazine.url}`} className="list-title-gck6">
                        {data?.magazine}
                    </GenericLink>
                    <ul className="sub-list-gck6">
                        <li>
                            <GenericLink to={`${links.magazine.url}?section=blog`} className="sub-list-title-gck6">
                                {data?.blog}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink
                                to={`${links.magazine.url}?section=case-history`}
                                className="sub-list-title-gck6"
                            >
                                {data?.case}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`${links.magazine.url}?section=academy`} className="sub-list-title-gck6">
                                {data?.academy}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`${links.magazine.url}?section=events`} className="sub-list-title-gck6">
                                {data?.events}
                            </GenericLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <GenericLink to={`${links.contacts.url}`} className="list-title-gck6">
                        {data?.contacts}
                    </GenericLink>
                    <ul className="sub-list-gck6">
                        <li>
                            <GenericLink to={`tel:${data?.phoneNumber}`} className="sub-list-title-gck6">
                                {data?.phoneNumber}
                            </GenericLink>
                        </li>
                        <li>
                            <GenericLink to={`mailto:${data?.email}`} className="sub-list-title-gck6">
                                {data?.email}
                            </GenericLink>
                        </li>
                        <li>
                            <SocialLinks className="social-links-gck6" />
                        </li>
                    </ul>
                </li>
                <li>
                    <span className="footer-mock-header-gck6">Privacy</span>
                    <ul className="sub-list-gck6">
                        <li>
                            <PrivacyPolicy className="sub-list-title-gck6">Privacy Policy</PrivacyPolicy>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    )
}

export default SiteMap
