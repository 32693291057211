import clsx from "clsx"
import React, {useEffect, useRef, useState} from "react"
import "./Curtain.css"

interface CurtainProps extends React.HTMLAttributes<HTMLDivElement> {
    visible: boolean
    callback?: (() => void) | undefined
}

type TransitionSteps = "hidden" | "entering" | "visible" | "exiting"

const Curtain: React.FC<CurtainProps> = props => {
    const {visible, callback, className, ...otherProps} = props
    const [step, setStep] = useState<TransitionSteps>("hidden")
    // const [transitioning, setTransitioning] = useState(false);
    const curtainRef = useRef<HTMLDivElement | null>(null)
    const feetRef = useRef<HTMLDivElement | null>(null)

    const enter = () => {
        const feet = feetRef.current
        if (!feet) return
        feet.ontransitionend = () => {
            feet.ontransitionend = null
            show()
        }
        // setTransitioning(true);
        setStep("entering")
    }

    const show = () => {
        const feet = feetRef.current
        if (!feet) return
        feet.ontransitionend = () => {
            feet.ontransitionend = null
            // setTransitioning(false);
            if (callback) callback()
        }
        setStep("visible")
    }

    const exit = () => {
        const feet = feetRef.current
        if (!feet) return
        feet.ontransitionend = () => {
            feet.ontransitionend = null
            hide()
        }
        // setTransitioning(true);
        setStep("exiting")
    }

    const hide = () => {
        const feet = feetRef.current
        if (!feet) return
        feet.ontransitionend = () => {
            feet.ontransitionend = null
            // setTransitioning(false);
        }
        setStep("hidden")
    }

    useEffect(() => {
        // if (transitioning) {
        //     return;
        // }
        if (visible && step === "hidden") {
            enter()
        }
        if (!visible && step === "visible") {
            exit()
        }
    }, [visible, step])

    return (
        <div className={clsx("curtain-jvg8", `${step}-jvg8`, className)} {...otherProps} ref={curtainRef}>
            <div className="head-jvg8" />
            <div className="feet-jvg8" ref={feetRef} />
        </div>
    )
}

export default Curtain
