import clsx from "clsx"
import GenericLink from "~/components/UI/GenericLink/GenericLink"
import FacebookIcon from "~/icons/FacebookIcon"
import LinkedInIcon from "~/icons/LinkedInIcon"
import {useSocialNetworksQuery} from "~/queries/SocialNetworksQuery"
import InstagramIcon from "../../../icons/InstagramIcon"
import TwitterIcon from "../../../icons/TwitterIcon"
import "./SocialLinks.css"

interface SocialLinksProps extends React.HTMLAttributes<HTMLDivElement> {}

const SocialLinks: React.FC<SocialLinksProps> = props => {
    const {className, ...otherProps} = props
    const socialNetworks = useSocialNetworksQuery()

    return (
        <div className={clsx("social-links-dra9", className)} {...otherProps}>
            <GenericLink to={socialNetworks?.facebookLink} className="link-dra9">
                <FacebookIcon className="icon-dra9" />
            </GenericLink>
            <GenericLink to={socialNetworks?.instagramLink} className="link-dra9">
                <InstagramIcon className="icon-dra9" />
            </GenericLink>
            <GenericLink to={socialNetworks?.twitterLink} className="link-dra9">
                <TwitterIcon className="icon-dra9" />
            </GenericLink>
            <GenericLink to={socialNetworks?.linkedInLink} className="link-dra9">
                <LinkedInIcon className="icon-dra9" />
            </GenericLink>
        </div>
    )
}

export default SocialLinks
