import {GatsbyBrowser} from "gatsby"
import React from "react"
import Layout from "./src/components/Layout"
import {AuthProvider} from "./src/contexts/AuthContext"
import {I18nProvider} from "./src/contexts/I18nContext"
import {NavBarContextProvider} from "./src/contexts/NavBarContext"
import {TransitionContextProvider} from "./src/contexts/TransitionContext"

// export const onClientEntry: GatsbyBrowser["onClientEntry"] = (args, options) => {}

// export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] = () => {}

// export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({location, prevLocation, ...otherProps}) => {}

// export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({location, prevLocation, ...otherProps}) => {}

const pathsToEsclude = [`${process.env.PATH_PREFIX}/`, `/${process.env.PATH_PREFIX}/`]

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element, props}) => {
    if (pathsToEsclude.includes(props.location.pathname)) return <>{element}</>
    return (
        <I18nProvider>
            <TransitionContextProvider reachLocation={props.location}>
                <AuthProvider>
                    <NavBarContextProvider>
                        <Layout {...props}>{element}</Layout>
                    </NavBarContextProvider>
                </AuthProvider>
            </TransitionContextProvider>
        </I18nProvider>
    )
}

// export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({element, pathname}) => {
//     return <>{element}</>
// }
