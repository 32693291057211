import {createContext, useEffect, useState} from "react"
import {useScrollDirection} from "react-use-scroll-direction"
import useNextRoute from "~/hooks/useNextRoute"
interface ContextInterface {
    isScrollingUp: boolean | undefined
    isHeroVisible: boolean | undefined
    show: (value?: number | undefined) => void
    hide: (value?: number | undefined) => void
}

const context: ContextInterface = {
    isScrollingUp: undefined,
    isHeroVisible: undefined,
    show: (value?: number | undefined) => {},
    hide: (value?: number | undefined) => {},
}

const NavBarContext = createContext(context)

interface NavBarContextProvider {
    children: React.ReactNode
}

export const NavBarContextProvider = (props: NavBarContextProvider) => {
    const {children} = props
    const [isScrollingUp, setScrollingUp] = useState<boolean | undefined>(false)
    const [isHeroVisible, setHeroVisible] = useState<boolean>(true)
    const [isImmutable, setImmutable] = useState<boolean>(false)
    const {scrollDirection} = useScrollDirection()
    let timeout: NodeJS.Timeout
    const nextRoute = useNextRoute()

    useEffect(() => {
        setScrollingUp(false)
    }, [nextRoute])

    useEffect(() => {
        if (isImmutable) return
        switch (scrollDirection) {
            case "UP":
                setScrollingUp(true)
                break
            case "DOWN":
                setScrollingUp(false)
                break
        }
    }, [scrollDirection, isHeroVisible])

    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, {threshold: 0.5})
        const hero = document.getElementById("hero")
        if (!hero) return
        observer.observe(hero)

        return () => {
            observer.unobserve(hero)
            observer.disconnect()
        }
    })

    const observerCallback: IntersectionObserverCallback = entries => {
        const entry = entries[0]
        if (!entry) return
        if (entry.isIntersecting) {
            setHeroVisible(true)
        } else {
            setHeroVisible(false)
        }
    }

    const showMenu = (value?: number | undefined) => {
        setImmutable(true)
        if (value && value > 1) {
            timeout = setTimeout(() => {
                setImmutable(false)
            }, value)
        }
        setScrollingUp(true)
    }

    const hideMenu = (value?: number | undefined) => {
        setImmutable(true)
        if (value && value > 1) {
            timeout = setTimeout(() => {
                setImmutable(false)
            }, value)
        }
        setScrollingUp(false)
    }

    return (
        <NavBarContext.Provider
            value={{
                isHeroVisible: isHeroVisible,
                isScrollingUp: isScrollingUp,
                hide: value => hideMenu(value),
                show: value => showMenu(value),
            }}
        >
            {children}
        </NavBarContext.Provider>
    )
}

export default NavBarContext
