const ChevronDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg fill={fill ? fill : "currentColor"} viewBox={viewBox ? viewBox : "0 0 48 48 "} {...otherProps}>
            <path d="M24 30.75 12 18.75 14.15 16.6 24 26.5 33.85 16.65 36 18.8Z" />
        </svg>
    )
}

export default ChevronDownIcon
