import clsx from "clsx"
import {forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef, useState} from "react"
import {useInView} from "react-hook-inview"
import NavBarContext from "~/contexts/NavBarContext"
import ChevronDownIcon from "~/icons/ChevronDownIcon"
import "./SectionContainer.css"

export interface SectionProps extends Omit<React.HTMLAttributes<HTMLElement>, "title"> {
    headerClass?: string | undefined
    contentClass?: string | undefined
    tabbed?: boolean | undefined
    onTabClick?: (() => void) | undefined
    hideTabOnMobile?: boolean | undefined
    title?: string | undefined | null
    customTab?: React.ReactNode
    marginPre?: boolean | undefined
    marginPost?: boolean | undefined
    fullWidth?: boolean | undefined
    withFadeInEffect?: boolean | undefined
    ref?: React.ForwardedRef<ScrollHandle> | undefined
}

export type ScrollHandle = {
    scrollIntoView: (options: ScrollIntoViewOptions) => void
}

const SectionContainer: React.FC<SectionProps> = forwardRef<ScrollHandle, SectionProps>((props, ref) => {
    const {
        tabbed,
        withFadeInEffect = false,
        onTabClick,
        hideTabOnMobile = false,
        title,
        customTab,
        fullWidth,
        headerClass,
        contentClass,
        className,
        children,
        marginPre = false,
        marginPost = false,
        ...otherProps
    } = props

    const contentRef = useRef<HTMLDivElement>(null)
    const navBarContext = useContext(NavBarContext)
    const [sectionRef, isSectionRefVisible] = useInView({threshold: 0.1})
    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, () => ({
        scrollIntoView: (options: ScrollIntoViewOptions) => {
            contentRef.current?.scrollIntoView(options)
        },
    }))

    useEffect(() => {
        if (isSectionRefVisible) setVisible(true)
    }, [isSectionRefVisible])

    const onSectionTabClick = () => {
        if (onTabClick) onTabClick()
        if (!contentRef.current) return
        navBarContext.hide(1000)
        contentRef.current.scrollIntoView({behavior: "smooth", block: "start"})
    }

    return (
        <section
            className={clsx(className, "section-u2zf", {
                "visible-u2zf": visible,
                "fade-in-u2zf": withFadeInEffect,
            })}
            {...otherProps}
            ref={sectionRef}
        >
            <Fragment>
                {(title || tabbed) && (
                    <header
                        className={clsx("header-u2zf", headerClass, {
                            "top-u2zf": marginPre,
                            "bottom-u2zf": marginPost,
                            "hide-on-mobile-u2zf": hideTabOnMobile,
                        })}
                    >
                        {title && <h4 className="title-u2zf no-margin">{title}</h4>}
                        {tabbed && (
                            <button className="tab-u2zf" onClick={onSectionTabClick}>
                                {customTab ? customTab : <ChevronDownIcon className="icon-u2zf" />}
                            </button>
                        )}
                    </header>
                )}
                <div ref={contentRef} className={clsx("content-u2zf", contentClass, {"full-width-u2zf": fullWidth})}>
                    {children}
                </div>
            </Fragment>
        </section>
    )
})

SectionContainer.displayName = "SectionContainer"

export default SectionContainer
