import {Script} from "gatsby-script"
import {FC, Fragment, useContext} from "react"
import I18nContext from "~/contexts/I18nContext"

const CookieSolution: FC = props => {
    const i18n = useContext(I18nContext)
    return (
        <Fragment>
            <Script id="cookie-solution-1">
                {i18n.language === "it"
                    ? `
                    var _iub = _iub || []
                    _iub.csConfiguration = {
                        askConsentAtCookiePolicyUpdate: true,
                        consentOnContinuedBrowsing: false,
                        countryDetection: true,
                        enableCcpa: true,
                        floatingPreferencesButtonCaptionColor: '#07975A',
                        floatingPreferencesButtonColor: '#FFFFFF',
                        floatingPreferencesButtonDisplay: 'anchored-center-left',
                        invalidateConsentWithoutLog: true,
                        perPurposeConsent: true,
                        siteId: 2724680,
                        whitelabel: false,
                        cookiePolicyId: 77024075,
                        lang: 'it',
                        banner: {
                            acceptButtonColor: '#07975A',
                            acceptButtonDisplay: true,
                            backgroundColor: '#FFFFFF',
                            brandBackgroundColor: '#FFFFFF',
                            closeButtonDisplay: false,
                            customizeButtonColor: '#07975A',
                            customizeButtonDisplay: true,
                            explicitWithdrawal: true,
                            listPurposes: true,
                            logo: null,
                            position: 'float-bottom-center',
                            rejectButtonColor: '#013353',
                            rejectButtonDisplay: true,
                            textColor: '#212121',
                        },
                        callback: {
                            onPreferenceExpressedOrNotNeeded: (preference) => {
                                dataLayer.push({
                                    iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
                                })
                                if (!preference) {
                                    dataLayer.push({
                                        event: 'iubenda_preference_not_needed',
                                    })
                                } else {
                                    if (preference.consent === true) {
                                        dataLayer.push({
                                            event: 'iubenda_consent_given',
                                        })
                                    } else if (preference.consent === false) {
                                        dataLayer.push({
                                            event: 'iubenda_consent_rejected',
                                        })
                                    } else if (preference.purposes) {
                                        for (var purposeId in preference.purposes) {
                                            if (preference.purposes[purposeId]) {
                                                dataLayer.push({
                                                    event: 'iubenda_consent_given_purpose_' + purposeId,
                                                })
                                            }
                                        }
                                    }
                                }
                            },
                        },
                    }`
                    : `var _iub = _iub || []
                    _iub.csConfiguration = {
                        askConsentAtCookiePolicyUpdate: true,
                        consentOnContinuedBrowsing: false,
                        countryDetection: true,
                        enableCcpa: true,
                        floatingPreferencesButtonCaptionColor: '#07975A',
                        floatingPreferencesButtonColor: '#FFFFFF',
                        floatingPreferencesButtonDisplay: 'anchored-center-left',
                        invalidateConsentWithoutLog: true,
                        perPurposeConsent: true,
                        siteId: 2724680,
                        whitelabel: false,
                        cookiePolicyId: 48365373,
                        lang: 'en',
                        banner: {
                            acceptButtonColor: '#07975A',
                            acceptButtonDisplay: true,
                            backgroundColor: '#FFFFFF',
                            brandBackgroundColor: '#FFFFFF',
                            closeButtonDisplay: false,
                            customizeButtonColor: '#07975A',
                            customizeButtonDisplay: true,
                            explicitWithdrawal: true,
                            listPurposes: true,
                            logo: null,
                            position: 'float-bottom-center',
                            rejectButtonColor: '#013353',
                            rejectButtonDisplay: true,
                            textColor: '#212121',
                        },
                        callback: {
                            onPreferenceExpressedOrNotNeeded: (preference) => {
                                dataLayer.push({
                                    iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
                                })
                                if (!preference) {
                                    dataLayer.push({
                                        event: 'iubenda_preference_not_needed',
                                    })
                                } else {
                                    if (preference.consent === true) {
                                        dataLayer.push({
                                            event: 'iubenda_consent_given',
                                        })
                                    } else if (preference.consent === false) {
                                        dataLayer.push({
                                            event: 'iubenda_consent_rejected',
                                        })
                                    } else if (preference.purposes) {
                                        for (var purposeId in preference.purposes) {
                                            if (preference.purposes[purposeId]) {
                                                dataLayer.push({
                                                    event: 'iubenda_consent_given_purpose_' + purposeId,
                                                })
                                            }
                                        }
                                    }
                                }
                            },
                        },
                    }
                    `}
            </Script>
            <Script id="cookie-solution-2" src="//cdn.iubenda.com/cs/ccpa/stub.js" />
            <Script id="cookie-solution-3" src="//cdn.iubenda.com/cs/iubenda_cs.js" async />
        </Fragment>
    )
}

export default CookieSolution
