const MailIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const {viewBox, fill, stroke, ...otherProps} = props
    return (
        <svg
            viewBox={viewBox ? viewBox : "0 0 24 24"}
            fill={fill ? fill : "currentColor"}
            stroke={stroke ? stroke : "currentColor"}
            {...otherProps}
        >
            <path
                d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"
                stroke="none"
            />
        </svg>
    )
}

export default MailIcon
