import clsx from "clsx"
import React from "react"
import "./NavBarButton.css"

interface NavBarMenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const NavBarMenuButton: React.FC<NavBarMenuButtonProps> = props => {
    const {children, className, ...otherProps} = props
    return (
        <button className={clsx("menu-button-ntk6", className)} {...otherProps}>
            {children}
        </button>
    )
}

export default NavBarMenuButton
