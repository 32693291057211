import {graphql, useStaticQuery} from "gatsby"
import {useContext} from "react"
import I18nContext from "~/contexts/I18nContext"

export const useLabelsQuery = () => {
    const i18n = useContext(I18nContext)

    const data: Queries.LabelsQuery = useStaticQuery(graphql`
        query Labels {
            allContentfulLabels {
                nodes {
                    contactUs
                    eMail
                    lastName
                    name
                    phoneNumber
                    privacyAcceptance
                    submitForm
                    node_locale
                    submitSuccessMessage
                    title
                    role
                    curriculum
                    introduction
                    ascending
                    category
                    customer
                    date
                    descending
                    showMore
                    sortBy
                    type
                    loading
                    moreArticles
                    noMatchingResult
                    tag
                    project
                    read
                    view
                    history
                    objective
                    presentation
                    solution
                    success
                    moreProjects
                    subscribeToOurNewsletter
                    candidate
                    advertiser
                    company
                    other
                    publisher
                    attachedFile
                    message
                    moreEvents
                    search
                    searchInputPlaceholder
                    all
                    downloadPdf
                    more
                    userMarketing
                    userProfiling
                }
            }
        }
    `)

    let labelsData = data.allContentfulLabels.nodes[0]!
    for (const translation of data.allContentfulLabels.nodes) {
        if (translation.node_locale === i18n.language) {
            labelsData = translation
            break
        }
    }
    return labelsData
}
