import clsx from "clsx"
import React, {useContext, useEffect, useRef} from "react"
import Button from "~/components/UI/Button/Button"
import GenericLink from "~/components/UI/GenericLink/GenericLink"
import NavBarContext from "~/contexts/NavBarContext"
import MailIcon from "~/icons/MailIcon"
import MenuIcon from "~/icons/MenuIcon"
import UserIcon from "~/icons/UserIcon"
import {useLabelsQuery} from "~/queries/LabelsQuery"
import {useLinksQuery} from "~/queries/LinksQuery"
import NavBarMenuButton from "./NavBarButton"

import {StaticImage} from "gatsby-plugin-image"
import "./NavBar.css"

export interface MenuProps extends React.HTMLAttributes<HTMLElement> {
    onMenuIconClick: (event: React.MouseEvent<HTMLElement>) => void
}

const NavBarContent: React.FC<MenuProps> = props => {
    const {onMenuIconClick, className, children, ...otherProps} = props
    const labels = useLabelsQuery()
    const links = useLinksQuery()

    const onSignupButtonClickHandler = () => {
        const ref = document.getElementById("subscription-mail-input")
        if (!ref) return
        ref.focus({preventScroll: true})
        ref.scrollIntoView({behavior: "smooth"})
    }

    return (
        <>
            <div className={clsx("nav-left-74qa", className)} {...otherProps}>
                <GenericLink to={links.homepage.url} className="link-74qa">
                    {children}
                </GenericLink>
            </div>
            <div className="nav-right-74qa">
                <Button
                    color="white"
                    className="subscribe-button-74qa"
                    onClick={onSignupButtonClickHandler}
                    type="button"
                >
                    {labels.subscribeToOurNewsletter}
                </Button>
                <NavBarMenuButton
                    className="mobile-menu-74qa mobile-subscribe-button-74qa"
                    onClick={onSignupButtonClickHandler}
                >
                    <MailIcon className="icon-74qa" />
                </NavBarMenuButton>
                {/* <GenericLink className="mobile-menu-74qa" to={`${links.authentication.url}`}>
                    <UserIcon className="icon-74qa" />
                </GenericLink> */}
                <NavBarMenuButton className="mobile-menu-74qa" onClick={onMenuIconClick}>
                    <MenuIcon className="icon-74qa" />
                </NavBarMenuButton>
            </div>
        </>
    )
}

const StaticNavBar: React.FC<MenuProps> = props => {
    const {className, onMenuIconClick, ...otherProps} = props

    return (
        <nav className={clsx("nav-74qa", className)} {...otherProps} id="static-nav-bar">
            <div className="nav-cont-74qa">
                <NavBarContent onMenuIconClick={onMenuIconClick}>
                    <StaticImage
                        src="./../../images/logo-white.png"
                        alt="Logo"
                        height={20}
                        placeholder="blurred"
                        className="logo-white-74qa"
                    />
                </NavBarContent>
            </div>
        </nav>
    )
}

const FixedNavBar: React.FC<MenuProps> = props => {
    const {className, onMenuIconClick, ...otherProps} = props
    const navBarContext = useContext(NavBarContext)
    const navRef = useRef<HTMLElement>(null)

    useEffect(() => {
        if (!navRef.current) return
        if (navBarContext.isScrollingUp === false || navBarContext.isHeroVisible === true) {
            navRef.current.classList.add("hidden-74qa")
        } else {
            navRef.current.classList.remove("hidden-74qa")
        }
    }, [navBarContext.isScrollingUp, navBarContext.isHeroVisible])

    return (
        <nav
            className={clsx("nav-74qa", "fixed-74qa", "hidden-74qa", className)}
            {...otherProps}
            ref={navRef}
            id="fixed-nav-bar"
        >
            <div className="nav-cont-74qa">
                <NavBarContent onMenuIconClick={onMenuIconClick}>
                    <StaticImage
                        src="./../../images/logo-black.png"
                        alt="Logo"
                        height={20}
                        placeholder="blurred"
                        className="logo-black-74qa"
                    />
                </NavBarContent>
            </div>
        </nav>
    )
}

const NavBar: React.FC<MenuProps> = props => {
    return (
        <>
            <StaticNavBar {...props} />
            <FixedNavBar {...props} />
        </>
    )
}

export default NavBar
