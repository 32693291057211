import {WindowLocation} from "@gatsbyjs/reach-router"
import {navigate} from "@reach/router"
import {ReactNode, createContext, useEffect, useState} from "react"
import Curtain from "~/components/UI/Curtain/Curtain"

interface TransitionContextInterface {
    isLoading: boolean
    setLoading: (value: boolean) => void
    nextRouteCallBack: () => void
    navigateTo: (value: string) => void
}

export const TransitionDefaultContextValues: TransitionContextInterface = {
    isLoading: false,
    setLoading: value => {},
    nextRouteCallBack: () => {},
    navigateTo: value => {},
}

const TransitionContext = createContext(TransitionDefaultContextValues)

interface TransitionContextProviderProps {
    reachLocation: WindowLocation
    children: ReactNode
}

export const TransitionContextProvider: React.FC<TransitionContextProviderProps> = props => {
    const {children, reachLocation, ...otherProps} = props
    const [isLoading, setLoading] = useState(false)
    const [nextRoute, setNextRoute] = useState<string | undefined>()

    useEffect(() => {
        setLoading(false)
    }, [reachLocation.href])

    const navigateTo = (value: string) => {
        if (value === reachLocation.href) {
            navigate(value)
            return
        }
        setNextRoute(value)
        setLoading(true)
    }

    const nextRouteCallBack = () => {
        if (nextRoute) navigate(nextRoute)
    }

    return (
        <TransitionContext.Provider
            value={{
                isLoading: isLoading,
                setLoading: setLoading,
                nextRouteCallBack: nextRouteCallBack,
                navigateTo: navigateTo,
            }}
            {...otherProps}
        >
            <Curtain visible={isLoading} callback={nextRouteCallBack} />
            {children}
        </TransitionContext.Provider>
    )
}

export default TransitionContext
